<template>
  <div>
    <img src="../assets/logos/ikea-logo.svg" />
    <div class="login-container" v-if="activeState === loginFormState.Login">
      <h1 class="welcome-title">Welcome to {{ title }}</h1>
      <dx-validation-group :ref="loginValidationRefKey" name="loginGroup">
        <div class="dx-field" style="z-index: 3 !important">
          <dx-text-box
            placeholder="Username"
            stylingMode="outlined"
            width="100%"
            :value.sync="userName"
          >
            <dx-validator>
              <dx-required-rule message="Username is required" />
            </dx-validator>
          </dx-text-box>
        </div>

        <div class="dx-field" style="z-index: 2 !important">
          <dx-text-box
            placeholder="Password"
            stylingMode="outlined"
            width="100%"
            mode="password"
            @enter-key="onLoginEnterKeyPress()"
            :value.sync="password"
          >
            <dx-validator>
              <dx-required-rule message="Password is required" />
            </dx-validator>
          </dx-text-box>
        </div>

        <div style="margin: 30px 0">
          <dx-button
            type="default"
            text="Login"
            ref="loginBtn"
            width="100%"
            @click="onLoginClick"
          />
        </div>
      </dx-validation-group>
      <p style="margin-bottom: 20px">
        <a
          style="cursor: pointer"
          @click="activeState = loginFormState.ResetPassword"
          >Forgotten password?</a
        >
      </p>

      <!-- <hr style="margin-top: 47px; margin-bottom: 0px" />
      <div class="login-section-divider">
        <span>OR</span>
      </div>
      <div>
        <dx-button
          type="normal"
          text="Login using SSO"
          ref=""
          width="100%"
          class="btn btn-large"
          style="background-color: white !important"
          icon="link"
          @click="onSSOLoginClick"
        />
      </div> -->
    </div>

    <div class="mfa-container" v-show="activeState === loginFormState.Mfa">
      <h1>Login Code</h1>
      <p>Please enter the code sent to your e-mail address.</p>
      <dx-validation-group :ref="mfaValidationRefKey" name="mfaGroup">
        <div class="dx-field">
          <dx-text-box
            placeholder="Code"
            stylingMode="outlined"
            width="100%"
            :value.sync="code"
            @enter-key="onMfaCodeEnterKeyPress()"
          >
            <dx-validator>
              <dx-required-rule message="Code is required" />
            </dx-validator>
          </dx-text-box>
        </div>

        <div class="dx-field">
          <dx-check-box
            :value.sync="rememberUserChecked"
            text="Remember me for 14 days"
          />
        </div>
        <div style="margin: 30px 0">
          <dx-button
            type="default"
            text="Submit code"
            ref="submitMfaBtn"
            width="100%"
            @click="onSubmitMfaClick"
          />
        </div>
      </dx-validation-group>
    </div>

    <div v-show="activeState === loginFormState.ResetPassword">
      <h1>Forgotten password?</h1>
      <p>Please enter your user name.</p>
      <dx-validation-group
        :ref="resetPasswordValidationRefKey"
        name="resetPasswordGroup"
      >
        <div class="dx-field">
          <dx-text-box
            placeholder="Username"
            stylingMode="outlined"
            width="100%"
            :value.sync="forgotPasswordUserName"
            @enter-key="onResetPasswordEnterKeyPress()"
          >
            <dx-validator>
              <dx-required-rule message="Username is required" />
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="positive-notification" v-show="showLinkSent">
          <i class="far fa-paper-plane mr-3"></i> A link has been sent by email,
          please check your inbox.
        </div>
        <div style="margin: 30px 0" v-show="!showLinkSent">
          <dx-button
            type="default"
            text="Next"
            ref="resetPasswordBtn"
            width="100%"
            @click="onSubmitResetPasswordClick"
          />
        </div>
      </dx-validation-group>
    </div>
    <p v-if="activeState !== loginFormState.Login">
      <a
        style="cursor: pointer"
        @click="
          activeState = loginFormState.Login;
          showLinkSent = false;
        "
        >Back</a
      >
    </p>
    <div :style="loaderDisplay" class="login-loader"></div>
  </div>
</template>

<script>
import {DxButton, DxCheckBox, DxTextBox} from "devextreme-vue";

import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidator, {DxRequiredRule} from "devextreme-vue/validator";
import {mapActions} from "vuex";
import notify from "devextreme/ui/notify";
import {loginFormState} from "../common/constants";
import {resetLocalStorage, isNotEmpty} from "../common/helperFunctions";

export default {
  data() {
    return {
      title: this.$appInfo.title,
      userName: "",
      password: "",
      mfaToken: "",
      forgotPasswordUserName: "",
      code: "",
      mfaValidationRefKey: "mfaValidationGroup",
      loginValidationRefKey: "loginValidationGroup",
      resetPasswordValidationRefKey: "resetPasswordValidationGroup",
      submitted: false,
      rememberUserChecked: false,
      rememberUser: null,
      showLinkSent: false,
      loaderDisplay: {},
      loginFormState: loginFormState,
      activeState: loginFormState.Login,
      deviceToken: "",
    };
  },
  created() {
    if (isNotEmpty(localStorage.getItem("rememberUser"))) {
      let rememberUser = JSON.parse(localStorage.getItem("rememberUser"));
      if (rememberUser.expiryDate && Date.now() < rememberUser.expiryDate) {
        this.rememberUser = rememberUser;
      } else {
        localStorage.removeItem("rememberUser");
      }
    }
  },
  methods: {
    ...mapActions("auth", ["login", "authenticateMfaCode"]),
    onLoginClick() {
      if (!this.loginValidationGroup.validate().isValid || this.submitted)
        return;
      Vue.set(this.loaderDisplay, "display", "block");
      let user = {
        userName: this.userName,
        password: this.password,
      };
      if (isNotEmpty(this.rememberUser)) {
        user = {
          ...user,
          deviceToken: this.rememberUser.deviceToken,
        };
      }
      this.submitted = true;

      this.login(user)
        .then((resp) => {
          this.submitted = false;
          if (resp.data.mfaChallenge === false) {
            resetLocalStorage();
            this.$router.push("/home");
          } else {
            Vue.set(this.loaderDisplay, "display", "none");
            this.activeState = loginFormState.Mfa;
            this.mfaToken = resp.data.mfaToken;
          }
        })
        .catch((err) => {
          this.submitted = false;
          Vue.set(this.loaderDisplay, "display", "none");
          if (err.response.data && err.response.data.lockedOut === true) {
            this.activeState = loginFormState.Login;
            this.code = "";
            this.userName = "";
            this.password = "";
            this.mfaToken = "";
            notify(
              `Too many failed login attempts. Your account has been locked out for 15 minutes. Please try again later.`,
              "error",
            );
          } else {
            this.userName = "";
            this.password = "";
          }
        });
    },
    onSSOLoginClick() {
      window.location = `${process.env.VUE_APP_API_HOSTNAME}/security/wsfed-challenge`;
    },
    onLoginEnterKeyPress() {
      this.$refs.loginBtn.$el.click();
    },
    onMfaCodeEnterKeyPress() {
      this.$refs.submitMfaBtn.$el.click();
    },
    onResetPasswordEnterKeyPress() {
      this.$refs.resetPasswordBtn.$el.click();
    },
    onSubmitResetPasswordClick(e) {
      if (!e.validationGroup.validate().isValid || this.submitted) return;
      this.$http
        .post("security/requestreset", {
          username: this.forgotPasswordUserName,
        })
        .then(() => {
          this.showLinkSent = true;
        })
        .catch(() => {
          notify(
            "Unable to send reset password request. please try again later.",
            "error",
          );
        });
    },
    onSubmitMfaClick(e) {
      if (!e.validationGroup.validate().isValid || this.submitted) return;

      Vue.set(this.loaderDisplay, "display", "block");
      this.submitted = true;
      let payload = {
        userName: this.userName,
        mfaToken: this.mfaToken,
        code: this.code,
        rememberDevice: this.rememberUserChecked,
      };
      this.authenticateMfaCode(payload)
        .then((resp) => {
          this.submitted = false;
          resetLocalStorage();
          this.$router.push("/home");
        })
        // eslint-disable-next-line no-console
        .catch((err) => {
          Vue.set(this.loaderDisplay, "display", "none");
          if (err.response.data && err.response.data.lockedOut === true) {
            this.activeState = loginFormState.Login;
            this.code = "";
            this.userName = "";
            this.password = "";
            this.mfaToken = "";
            notify(
              `Too many failed login attempts. Your account has been locked out for 15 minutes. Please try again later.`,
              "error",
            );
          } else {
            this.code = "";
            notify(
              `The code entered was not accepted. Please try again.`,
              "error",
            );
          }
          this.submitted = false;
          this.code = "";
        });
    },
  },
  computed: {
    loginValidationGroup: function () {
      return this.$refs[this.loginValidationRefKey].instance;
    },
    mfaValidationGroup: function () {
      return this.$refs[this.mfaValidationRefKey].instance;
    },
    resetPasswordValidationGroup: function () {
      return this.$refs[this.resetPasswordValidationRefKey].instance;
    },
  },
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
    DxCheckBox,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.welcome-title {
  margin-bottom: 30px;
}
.login-header {
  text-align: center;

  .title {
    color: $base-accent;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
}

.login-section-divider {
  margin-top: -15px;
  margin-bottom: 35px;
  span {
    display: inline-block;
    background: white;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #e8e8e8;
    font-size: 11px;
    font-weight: bold;
  }
}
.positive-notification {
  background: white;
  color: green;
  padding: 10px;
  margin-bottom: 25px;
  font-size: 15px;
}
</style>
